.page-index {
  overflow: hidden;
  background-color: #fcf4f3;
}

.page-index .x-app-body {
  padding: 0;
}

.page-index .banner {
  position: relative;
  height: 11.26rem;
  overflow: hidden;
  background: url("./imgs/bg.png") no-repeat 50%/cover;
}

.page-index .banner .slogan {
  position: absolute;
  top: 0.4rem;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 11.02rem;
  background: url("./imgs/slogan.png") no-repeat 50%/cover;
  animation: infiniteScale 5s linear infinite;
  pointer-events: none;
}

.page-index .banner .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 7.5rem;
  height: 7.5rem;
  margin: -4.75rem 0 0 -3.75rem;
  background: url("./imgs/circle.png") no-repeat 50%/cover;
  animation: infiniteRotate 5s linear infinite;
  pointer-events: none;
}

.page-index .banner .order {
  position: absolute;
  top: 0.8rem;
  right: 0.2rem;
  z-index: 10;
  color: #fff;
  background-color: #f13b3b;
}

.page-index .banner .img {
  display: block;
  width: 100%;
}

.page-index .banner .marquee {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.6rem;
  font-size: 0.3rem;
  line-height: 0.6rem;
  background-color: rgba(241,59,59,0.5);
}

.page-index .banner .marquee .inner {
  color: #fff;
  word-wrap: normal;
  word-break: keep-all;
  animation: marquee 30s linear infinite;
}

.page-index .form {
  position: relative;
  margin-top: -3rem;
  padding: 0.2rem;
}

.page-index .form .form-inner {
  padding: 0.4rem 0.2rem 0.2rem 0.2rem;
  background-color: #fff;
  border-radius: 0.1rem;
}

.page-index .form .input-wrapper {
  margin-bottom: 0.2rem;
}

.page-index .form .input-wrapper .item {
  display: flex;
  margin-bottom: 0.2rem;
}

.page-index .form .input-wrapper .item .label {
  flex-shrink: 0;
  padding: 0 0.2rem 0 0.1rem;
  color: #fff;
  font-size: 0.28rem;
  line-height: 0.78rem;
  background-color: #f8a29b;
  border-radius: 0.08rem 0 0 0.08rem;
}

.page-index .form .input-wrapper .item .input {
  display: flex;
  flex: 1;
  padding-left: 0.2rem;
  overflow: hidden;
  background-color: #fff;
  border-top: solid 0.01rem #d2ab8b;
  border-right: solid 0.01rem #d2ab8b;
  border-bottom: solid 0.01rem #d2ab8b;
  border-radius: 0 0.08rem 0.08rem 0;
}

.page-index .form .input-wrapper .item .input>input {
  display: block;
  flex: 1;
  width: 100%;
  height: 0.78rem;
  font-size: 0.3rem;
  border: none;
}

.page-index .form .input-wrapper .label {
  font-size: 0.28rem;
}

.page-index .form .agreement {
  display: flex;
  align-items: flex-start;
  font-size: 0.26rem;
}

.page-index .form .agreement .text {
  padding: 0.1rem 0;
  color: #e56767;
  line-height: 0.32rem;
}

.page-index .form .agreement .text a {
  color: #f15424;
  text-decoration: underline;
}

.page-index .form .agreement .checkbox.x-radio--checked .x-radio__icon {
  background: #d45a5a;
}

.page-index .form .btns {
  padding-top: 0.4rem;
}

.page-index .form .btns .submit {
  height: 1.1rem;
  font-weight: bold;
  font-size: 0.4rem;
  background-color: #d45a5a;
  border-radius: 0.55rem;
  animation: sharke 1s linear infinite;
}

.page-index .form .tip {
  margin-top: 0.2rem;
  padding: 0.1rem 0;
  color: #666;
  font-size: 0.26rem;
  text-align: center;
}

.page-index .form .tip em {
  color: #e56767;
  font-weight: bold;
  font-style: normal;
}

.page-index .more {
  padding-bottom: 1rem;
}

.page-index .more h2 {
  color: #f13b3b;
  font-size: 0.4rem;
  text-align: center;
}

.page-index .more .cx {
  width: 3.78rem;
  height: 0.64rem;
  margin: 0 auto;
  margin-top: 0.2rem;
  background: url("./imgs/cxs.png") no-repeat 50%/cover;
}

.page-index .more .company {
  color: #e56767;
  font-size: 0.2rem;
  text-align: center;
}

.page-index .more .tip-warning {
  color: #b1afaf;
  font-size: 0.2rem;
  text-align: center;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes infiniteRotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes infiniteScale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes sharke {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}