.page-result {
  background-color: #e6d5d3;
}

.page-result .body {
  padding: 0;
  padding-bottom: 0.5rem;
}

.page-result .body .banner img {
  display: block;
  width: 100%;
}

.page-result .body .base-info {
  padding: 0.4rem;
}

.page-result .body .base-info .base {
  display: flex;
  margin-top: 0.1rem;
}

.page-result .body .base-info .base .title {
  color: #720000;
  font-weight: bold;
}

.page-result .body .base-info .base .value {
  color: #666;
  font-weight: bold;
}

.page-result .body .bazi {
  padding: 0.4rem;
}

.page-result .body .bazi .title {
  margin-bottom: 0.2rem;
}

.page-result .body .bazi .title img {
  display: block;
  width: 100%;
}

.page-result .body .bazi .content table th,
.page-result .body .bazi .content table td {
  width: 20%;
  line-height: 0.6rem;
  text-align: center;
}

.page-result .body .bazi .content table th {
  color: #720000;
}

.page-result .body .bazi .content .ys {
  display: flex;
  justify-content: space-between;
  margin: 0.2rem;
}

.page-result .body .bazi .content .ys .ys-item .b {
  color: #720000;
  font-weight: bold;
}

.page-result .body .result-list {
  padding-bottom: 0.4rem;
}

.page-result .body .result-list .result-item {
  padding: 0.2rem 0.4rem;
}

.page-result .body .result-list .result-item .title {
  margin-bottom: 0.2rem;
}

.page-result .body .result-list .result-item .title img {
  display: block;
  width: 100%;
}

.page-result .body .result-list .result-item .content {
  padding: 0.2rem;
  text-indent: 2em;
  border: 0.01rem solid #720000;
  border-radius: 0.1rem;
}

.page-result .ads {
  position: relative;
  display: block;
  padding: 0 0.4rem 0.4rem 0.4rem;
  overflow: hidden;
  border-radius: 0.05rem;
}

.page-result .ads .ad {
  display: block;
  margin-bottom: 0.3rem;
}

.page-result .ads .ad.name {
  animation: sharke 2s linear infinite;
}

.page-result .ads .ad.qinggan {
  animation: sharke 2s linear 1.2s infinite;
}

.page-result .ads .ad.hehun {
  animation: sharke 2s linear 1.5s infinite;
}

.page-result .ads img {
  display: block;
  width: 100%;
  border-radius: 0.05rem;
}

.page-result .ads h2 {
  position: absolute;
  bottom: 0.3rem;
  width: 100%;
  color: #fff;
  font-size: 0.36rem;
  text-align: center;
}

@keyframes sharke {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes circleAnimationn {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}