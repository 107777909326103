.page-pay .body {
  padding: 0;
  padding-bottom: 0.8rem;
  background: #fbc7d8;
}

.page-pay .body .banner {
  position: relative;
  margin: 0.3rem 0.3rem 0.3rem;
  padding: 0.2rem;
  padding-top: 0.9rem;
  background-color: #fff;
  border: solid 0.05rem #f8dce5;
  border-radius: 0.1rem;
}

.page-pay .body .banner .title {
  position: absolute;
  top: 0;
  left: 50%;
  width: 5rem;
  margin-left: -2.5rem;
  padding: 0.1rem 0;
  color: #c73a40;
  font-weight: bold;
  font-size: 0.42rem;
  line-height: 0.6rem;
  text-align: center;
  background-color: #ffe5e4;
  border-radius: 0 0 0.4rem 0.4rem;
}

.page-pay .body .banner .cc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.page-pay .body .banner .cc .cc-item {
  display: block;
  flex-shrink: 0;
}

.page-pay .body .banner .cc .cc-item.c1 {
  width: 1.74rem;
  height: 1.74rem;
  background: url("./imgs/y1.png") no-repeat 50%/cover;
  animation: huanghuang 3s linear infinite;
}

.page-pay .body .banner .cc .cc-item.c2 {
  width: 2.16rem;
  height: 2.16rem;
  background: url("./imgs/y2.png") no-repeat 50%/cover;
  animation: huanghuang 3s linear 1s infinite;
}

.page-pay .body .banner .cc .cc-item.c3 {
  width: 1.74rem;
  height: 1.74rem;
  background: url("./imgs/y3.png") no-repeat 50%/cover;
  animation: huanghuang 3s linear 2s infinite;
}

.page-pay .body .banner .cc .cc-item.c4 {
  width: 1.42rem;
  height: 1.42rem;
  background: url("./imgs/y4.png") no-repeat 50%/cover;
  animation: huanghuang 3s linear infinite;
}

.page-pay .body .banner .cc .cc-item.c5 {
  width: 1.42rem;
  height: 1.42rem;
  background: url("./imgs/y5.png") no-repeat 50%/cover;
  animation: huanghuang 3s linear 1s infinite;
}

.page-pay .body .banner .cc .cc-item.c6 {
  width: 1.42rem;
  height: 1.42rem;
  background: url("./imgs/y6.png") no-repeat 50%/cover;
  animation: huanghuang 3s linear infinite;
}

.page-pay .body .banner .cc .cc-item.c7 {
  width: 1.42rem;
  height: 1.42rem;
  background: url("./imgs/y7.png") no-repeat 50%/cover;
  animation: huanghuang 3s linear 3s infinite;
}

.page-pay .body .banner .btns {
  padding-top: 0.4rem;
}

.page-pay .body .banner .btns .safe {
  text-align: center;
}

.page-pay .body .banner .btns .submit {
  position: relative;
  height: 1.1rem;
  font-weight: bold;
  font-size: 0.46rem;
  background-color: #d45a5a;
  border-radius: 0.55rem;
  animation: sharke 1s linear infinite;
}

.page-pay .body .banner .btns .submit sub {
  position: absolute;
  top: 0;
  right: 0.2rem;
  width: 1.55rem;
  height: 0.44rem;
  font-size: 0.24rem;
  background: url("./imgs/texs.png") no-repeat 50%/cover;
}

.page-pay .body .more {
  padding-bottom: 1rem;
}

.page-pay .body .more h2 {
  color: #f13b3b;
  font-size: 0.4rem;
  text-align: center;
}

.page-pay .body .more .cx {
  width: 3.78rem;
  height: 0.64rem;
  margin: 0 auto;
  margin-top: 0.2rem;
  background: url("./imgs/cxs.png") no-repeat 50%/cover;
}

.page-pay .body .more .company {
  color: #e56767;
  font-size: 0.2rem;
  text-align: center;
}

.page-pay .body .more .tip-warning {
  color: #b1afaf;
  font-size: 0.2rem;
  text-align: center;
}

.page-pay .body .price {
  color: #020202;
  font-weight: bold;
  font-size: 0.28rem;
  text-align: center;
}

.page-pay .body .price em {
  color: #e93323;
  font-size: 0.5rem;
  font-style: normal;
}

.page-pay .body .countdown {
  padding: 0.1rem 0;
  color: #000;
  font-size: 0.28rem;
  text-align: center;
}

.page-pay .body .pay-tip {
  margin: 0.4rem 0;
  color: #333;
  line-height: 0.8rem;
  text-align: center;
  border-top: solid 0.01rem #d2ab8b;
  border-bottom: solid 0.01rem #d2ab8b;
}

.page-pay .body .result-list {
  display: block;
  padding: 0.4rem;
}

.page-pay .body .result-list img {
  display: block;
  width: 100%;
}

.page-pay .body .comment {
  padding: 0 0.4rem 0.4rem 0.4rem;
}

.page-pay .footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: none;
}

.page-pay .footer .btns {
  padding: 0.4rem;
}

.page-pay .footer .btns .submit {
  position: relative;
  height: 1.1rem;
  font-weight: bold;
  font-size: 0.46rem;
  background-color: #d45a5a;
  border-radius: 0.55rem;
  animation: sharke 1s linear infinite;
}

.page-pay .footer .btns .submit sub {
  position: absolute;
  top: 0;
  right: 0.2rem;
  width: 1.55rem;
  height: 0.44rem;
  font-size: 0.24rem;
  background: url("./imgs/texs.png") no-repeat 50%/cover;
}

.pop-pay .close {
  position: absolute;
  top: 0.2rem;
  right: 0.4rem;
  color: #666;
}

.xx-qaaaa {
  padding: 0.4rem 0;
}

.xx-qaaaa p {
  font-weight: bold;
  font-size: 0.28rem;
  line-height: 0.5rem;
  text-align: center;
}

.xx-qaaaa .tip {
  margin-top: 0.2rem;
  padding: 0.1rem 0;
  color: #666;
  font-size: 0.3rem;
  text-align: center;
}

.xx-qaaaa .tip em {
  color: #e56767;
  font-weight: bold;
  font-style: normal;
}

.pay-button {
  padding: 0 0.4rem;
}

.pay-button .btn {
  margin: 0.2rem 0;
  font-weight: bold;
  font-size: 0.4rem;
}

.pay-button .btn .icon {
  width: 0.6rem;
  height: 0.6rem;
  margin: 0 0.1rem;
}

.pay-button .btn.wechat {
  background-color: #07c160;
}

.pay-button .btn.wechat .icon {
  background: url("./imgs/icon-wechat.png") no-repeat 50%/cover;
}

.pay-button .btn.alipay {
  background-color: #1296db;
}

.pay-button .btn.alipay .icon {
  background: url("./imgs/icon-alipay.png") no-repeat 50%/cover;
}

@keyframes huanghuang {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(0.2rem);
  }

  50% {
    transform: translateX(0);
  }

  75% {
    transform: translateX(-0.2rem);
  }

  100% {
    transform: translateX(0);
  }
}